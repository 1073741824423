// import PropTypes from 'prop-types'
// import React, { useEffect } from "react"

// import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

// // Redux
// import { Link } from "react-router-dom"
// import withRouter from '../../components/Common/withRouter';

// import { useSelector, useDispatch } from "react-redux";
// import { createSelector } from "reselect";
// // Formik validation
// import * as Yup from "yup";
// import { useFormik } from "formik";

// //Social Media Imports
// import { GoogleLogin } from "react-google-login"
// // import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// // actions
// import { loginUser, socialLogin } from "../../store/actions"

// // import images
// import logo from "../../assets/images/logo-dark.png"
// import logolight from "../../assets/images/logo-light.png"

// //Import config
// import { facebook, google } from "../../config"

// //Import image login
// import imagelogin from "../../assets/images/imagelogin.jpg"

// const Login = (props) => {

//   document.title = " Login | Minible - Responsive Bootstrap 5 Admin Dashboard"

//   const dispatch = useDispatch();

//   const validation = useFormik({
//     // enableReinitialize : use this flag when initial values needs to be changed
//     enableReinitialize: true,

//     initialValues: {
//       email: "admin@themesbrand.com" || '',
//       password: "123456" || '',
//     },
//     validationSchema: Yup.object({
//       email: Yup.string().required("Please Enter Your Email"),
//       password: Yup.string().required("Please Enter Your Password"),
//     }),
//     onSubmit: (values) => {
//       dispatch(loginUser(values, props.router.navigate));
//     }
//   });


//   const selectLoginState = (state) => state.Login;
//   const LoginProperties = createSelector(
//     selectLoginState,
//     (login) => ({
//       error: login.error
//     })
//   );

//   const {
//     error
//   } = useSelector(LoginProperties);

//   const signIn = (res, type) => {

//     if (type === "google" && res) {

//       const postData = {
//         name: res.profileObj.name,
//         email: res.profileObj.email,
//         token: res.tokenObj.access_token,
//         idToken: res.tokenId,
//       }
//       dispatch(socialLogin(postData, props.router.navigate, type));
//     } else if (type === "facebook" && res) {

//       const postData = {
//         name: res.name,
//         email: res.email,
//         token: res.accessToken,
//         idToken: res.tokenId,
//       }
//       dispatch(socialLogin(postData, props.router.navigate, type))
//     }
//   }

//   //handleGoogleLoginResponse
//   const googleResponse = response => {
//     signIn(response, "google")
//   }

//   //handleFacebookLoginResponse
//   const facebookResponse = response => {
//     signIn(response, "facebook")
//   }

//   useEffect(() => {
//     document.body.className = "authentication-bg";
//     // remove classname when component will unmount
//     return function cleanup() {
//       document.body.className = "";
//     };
//   });

//   return (
//     <div >


//     <React.Fragment>
//       <div className="account-pages my-5 pt-sm-5" >
//         <Row>
//           <Col lg={6}>
//             <Container>
//               <center><img src={imagelogin} className='img-fluid' alt="" style={{ width: "70%", height:"511px", marginTop: "13%" }} /></center>
//             </Container>
//           </Col>
//           <Col lg={6}>
//             <Container>
//               <Row >
//                 <Col lg={12}>
//                   <div className="text-center">
//                     <Link to="/" className="mb-5 d-block auth-logo">
//                       <img src={logo} alt="" height="22" className="logo logo-dark" />
//                       <img src={logolight} alt="" height="22" className="logo logo-light" />
//                     </Link>
//                   </div>
//                 </Col>
//               </Row>
//               <Row className="align-items-center justify-content-center">
//                 <Col lg={10}/* md={8} lg={6} xl={5} */>
//                   <Card>
//                     <CardBody className="p-4">
//                       <div className="text-center mt-2">
//                         <h5 className="text-primary">Content de vous revoir !</h5>
//                         <p className="text-muted">Connectez-vous pour continuer sur <b>RH OBIDON</b></p>
//                       </div>
//                       <div className="p-2 mt-4">
//                         <Form
//                           className="form-horizontal"
//                           onSubmit={(e) => {
//                             e.preventDefault();
//                             validation.handleSubmit();
//                             return false;
//                           }}
//                         >
//                           {error ? <Alert color="danger">{error}</Alert> : null}

//                           <div className="mb-3">
//                             <Label className="form-label">Email</Label>
//                             <Input
//                               name="email"
//                               className="form-control"
//                               placeholder="Enter email"
//                               type="email"
//                               onChange={validation.handleChange}
//                               onBlur={validation.handleBlur}
//                               value={validation.values.email || ""}
//                               invalid={
//                                 validation.touched.email && validation.errors.email ? true : false
//                               }
//                             />
//                             {validation.touched.email && validation.errors.email ? (
//                               <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
//                             ) : null}
//                           </div>

//                           <div className="mb-3">
//                             {/* <div className="float-end">
//                               <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
//                             </div> */}
//                             <Label className="form-label">Password</Label>
//                             <Input
//                               name="password"
//                               value={validation.values.password || ""}
//                               type="password"
//                               placeholder="Enter Password"
//                               onChange={validation.handleChange}
//                               onBlur={validation.handleBlur}
//                               invalid={
//                                 validation.touched.password && validation.errors.password ? true : false
//                               }
//                             />
//                             {validation.touched.password && validation.errors.password ? (
//                               <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
//                             ) : null}
//                           </div>

//                           {/* <div className="form-check">
//                             <input
//                               type="checkbox"
//                               className="form-check-input"
//                               id="customControlInline"
//                             />
//                             <label
//                               className="form-check-label"
//                               htmlFor="customControlInline"
//                             >
//                               Remember me
//                             </label>
//                           </div> */}

//                           <div className="mt-3">
//                             <button
//                               className="btn btn-primary w-100 waves-effect waves-light"
//                               type="submit"
//                             >
//                               Log In
//                             </button>
//                           </div>

//                           <div className="mt-4 text-center">
//                             {/* <h5 className="font-size-14 mb-3">Sign in with</h5> */}
//                             <a href="">Mot de passe oublié</a>

//                             {/* <ul className="list-inline">
//                               <li className="list-inline-item">
//                                 <FacebookLogin
//                                   appId={facebook.APP_ID}
//                                   autoLoad={false}
//                                   callback={facebookResponse}
//                                   render={renderProps => (
//                                     <Link
//                                       to="#"
//                                       className="social-list-item bg-primary text-white border-primary"
//                                       onClick={renderProps.onClick}
//                                     >
//                                       <i className="mdi mdi-facebook" />
//                                     </Link>
//                                   )}
//                                 />
//                               </li>
//                               {google.CLIENT_ID !== '' &&
//                                 <li className="list-inline-item">
//                                   <GoogleLogin
//                                     clientId={google.CLIENT_ID}
//                                     render={renderProps => (
//                                       <Link
//                                         to="#"
//                                         className="social-list-item bg-danger text-white border-danger"
//                                         onClick={renderProps.onClick}
//                                       >
//                                         <i className="mdi mdi-google" />
//                                       </Link>
//                                     )}
//                                     onSuccess={googleResponse}
//                                     onFailure={() => { }}
//                                   />
//                                 </li>
//                               }
//                             </ul> */}
//                           </div>

//                           <div className="mt-4 text-center">
//                             <p className="mb-0">Don&apos;t have an account ? <a href="/register" className="fw-medium text-primary"> Signup now </a> </p>
//                           </div>
//                           <hr className='border-secondary'/>
//                           <p>
//                             <h6><center className='fw-medium'>Toutes utilisations frauduleuses est interdites et passible de poursuites judiciaires</center></h6>
//                           </p>

//                         </Form>

//                       </div>
//                     </CardBody>
//                   </Card>
//                   <div className="mt-5 text-center">
//                     <p>© {new Date().getFullYear()} Minible. Crafted with <i
//                       className="mdi mdi-heart text-danger"></i> by Themesbrand
//                     </p>
//                   </div>
//                 </Col>
//               </Row>
//             </Container>
//           </Col>
//         </Row>

//       </div>
//     </React.Fragment>
//     </div>
//   )
// }

// export default withRouter(Login);

// Login.propTypes = {
//   error: PropTypes.any,
//   history: PropTypes.object,
//   loginUser: PropTypes.func,
//   socialLogin: PropTypes.func
// }



import React, { useState } from "react"
import axios from 'axios'
import "./Chargement.css"
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imagelogin from "../../assets/images/imagelogin.jpg"
import logo from "../../assets/images/images.png"
import './Login.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Login() {
  const [donnees, setDonnees] = useState({
    "email": "",
    "password": ""
  })

  const [isloading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault()

    setIsLoading(true)

    try {
      const response = await axios.post("https://apirh.obidoninternational.com/login", donnees)
      console.log(response)

      // setResultApi(response.data.message)
      if (response.status === 201) {
        toast.success("Success");
        console.log({data : response.data})
        localStorage.setItem('authUser', JSON.stringify(response.data))
        navigate('/dashbord')
      
      } else if (response.status == 202) {
        toast.error(response.data);
      }

    } catch (error) {
      // console.error('Error lors de l\'appelle de l\'API', error)
      // const errorMessage = error.response ? error.response.data.message : 'Une erreur s\'est produite';
      // toast.error(errorMessage);
      toast.error('Erreur lors de l\'authentification');
      if (error.response && error.response.data) {

        toast.error(error.response.data.message || error.response.data)
      } else {

        toast.error('An error occurred during the API call')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (name, value) => {
    setDonnees(prev => ({
      ...prev,
      [name]: value
    }))
  }


  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (

    // {<div class="page-conteneurssss container" >
    //     <div className="row">
    //         <ToastContainer
    //             position="top-center"
    //             autoClose={5000}
    //             hideProgressBar={false}
    //             newestOnTop={false}
    //             closeOnClick
    //             rtl={false}
    //             pauseOnFocusLoss
    //             draggable
    //             pauseOnHover
    //             className="toast-container"
    //         />
    //         <div className="col-7" >
    //             <img src={imagelogin} alt="" />
    //         </div>
    //         <div className="col-5">
    //             <div class="card container shadow mb-5 formulaire-conteneurssss" style={style} >
    //                 {/* {resultApi && <div className="alert alert-info mt-2 'fade show' : 'fade' text-center" style={{width: "100%"}}>{resultApi}</div>} */}
    //                 <form onSubmit={handleSubmit} action="home" method="get">
    //                     <div class="card-body">
    //                         <center><h6 class="card-title bg-primary py-3 fw-bold  py-1 px-1" style={{ "width": "75%", color: "white" }}>Content de vous revoir</h6> </center><br />
    //                         <div className="row mb-3">
    //                             <div style={{ fontSize: "16px" }} className="col-lg-12">
    //                                 <h6 className="text-muted"> Connectez-vous pour continuer sur OBIDON ERP .
    //                                     L'utilisation de ce logiciel est réservée à VERTS FLEURIS
    //                                 </h6>
    //                             </div>
    //                         </div>
    //                         <div className="row container" >
    //                             <div className="col-lg-12" style={{ fontSize: "16px" }}>
    //                                 <Input texte="Email" type="email" value={email} onChange={setEmail} required />
    //                             </div>
    //                         </div>
    //                         <div className="row container" >
    //                             <div className="col-lg-12" style={{ fontSize: "16px" }}>
    //                                 <Input texte="Password" type="password" value={password} onChange={setPassword} required />
    //                             </div>
    //                         </div>
    //                         <div className="row ">
    //                             <div className="col-lg-12" style={{ fontSize: "16px" }}>
    //                                 <h6 className="text-muted">Toutes utilisations frauduleuses est interdite et passible de poursuites judicaires</h6>
    //                             </div>
    //                         </div>
    //                     </div><center>
    //                         <button style={{ width: "50%", color: "white" }} type="submit" className="btn bg-primary mb-3 pt-2">
    //                             {isloading ? (<div>
    //                                 <span className="spinner"></span>
    //                                 <span> Patientez...</span>
    //                             </div>) : (
    //                                 <h6 className="fw-bold">Se connecter</h6>
    //                             )}
    //                         </button>
    //                     </center>
    //                 </form>
    //             </div>
    //         </div>

    //     </div>
    // </div>}


    <div className="login-container">
      <ToastContainer 
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="login-left">
        <img src={imagelogin} alt="Illustration" />
      </div>
      <div className="login-right">
        <img className="logo" src={logo} alt="Logo" />
        <div style={{ backgroundColor: '#f2f6ff', padding: "20px", borderRadius: "10px" }}>
          <h2 className="fw-bold text-dark text-center ">Rh Obidon</h2>
          <p>Connectez-vous pour continuer sur <b>RH OBIDON</b></p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input type="email" className="form-control" required onChange={(e) => handleChange("email", e.target.value)} placeholder="Email" />
            </div>
            <div className="form-group">
              {/* <input type="password" className="form-control" placeholder="Mot de passe" />
                        <button type="button" className="toggle-password">
                            <FontAwesomeIcon icon={faEye} />
                        </button> */}

              <div style={{ position: 'relative', top: "-4px" }}>
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control"
                  required
                  id="password"
                  placeholder="Password"
                  onChange={(e) => handleChange("password", e.target.value)}
                />
                <button
                  type="button"
                  className="toggle-password"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  <FontAwesomeIcon style={{ color: "#0a368a" }} icon={passwordVisible ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>
            {/* <div className="form-group">
                        <select className="form-control">
                            <option value="fr">French (Français)</option>
                            <option value="en">English</option>
                        </select>
                    </div> */}
            <button type="submit" style={{position:"relative", top: "-8px"}}>{isloading ? (<div>
              <span className="spinner"></span>
              <span> Patientez...</span>
            </div>) : (
              <h6 className="fw-bold text-white">Connexion</h6>
            )}</button>
          </form>
          <center><a href="#" className="forgot-password">Mot de passe oublié</a></center>
          
          <hr className="border-secondary" />
          <center>  <p>© {new Date().getFullYear()}
          </p></center>

        </div>
      </div>
    </div>
  )
}

export default Login
